/* You can add global styles to this file, and also import other style files */

@import "fonts.scss";
@import "variables.scss";

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: var(--font-default);
}

h2,
h3 {
    font-size: 17px;
    font-weight: 400;
}

body {
    background-color: transparent;
    position: relative;
    overflow-y: hidden; //prevents scrollbar flashing in iframe

    &:not(.iframe) {
        background-color: var(--background-color);
        overflow-y: initial;
    }
}

input[type="text"],
input[type="tel"],
select,
option {
    border: 1px solid var(--input-border-color);
    background-color: var(--input-border-color);
    color: #242b33;
    padding: 0.5rem;
    font-size: 1rem;
    border-radius: 10px;
    font-family: Trebuchet MS;
    outline: none;

    &:focus,
    &:active {
        outline: 1px solid #a2a7be;
    }
}

input[type="submit"],
button.submit {
    padding: 0.8rem 1rem;
    text-align: center;
    background-color: var(--button-background-color);
    border: 1px solid var(--button-border-color);
    border-radius: var(--button-radius);
    font-size: 17px;
    color: var(--button-text-color);

    &:hover {
        background-color: var(--button-hover);
    }
}

input[type="submit"]:disabled,
input[type="submit"]:disabled:hover,
button.submit:disabled,
button.submit:disabled:hover {
    background-color: var(--button-disabled);
    border: 1px solid var(--button-disabled);
    cursor: default;
}

input[type="submit"],
button,
input[type="radio"] {
    cursor: pointer;
    display: block;
}

button.inverted {
    width: 100%;
    background-color: var(--background-color);
    padding: 0.75rem;
    color: var(--button-background-color);
    border: 2px solid var(--button-background-color);
    border-radius: 10px;
    font-size: 18px;
    margin-top: auto;
}

.hide {
    display: none !important;
}

a {
    text-decoration: none;

    &:hover {
        text-decoration: underline;
        text-decoration-thickness: 2px;
    }
}

.block {
    cursor: initial;
    pointer-events: none;
}

.modal-container {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;
}

.container {
    padding: 1rem 1.2rem;
}

label {
    color: var(--text-color-secondary);
    display: block;
    padding-bottom: 0.5rem;
}

@keyframes loop {
    from {
        transform: rotate(0);
    }

    to {
        transform: rotate(360deg);
    }
}

@media screen and (min-width: 700px) {
    body:not(.iframe) {
        background-color: var(--desktop-background-color);
    }
}
