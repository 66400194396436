@font-face {
    font-family: "Gentona Medium";
    src: local("Gentona Medium"), url(./assets/fonts/Gentona-Medium.woff) format("woff");
}

@font-face {
    font-family: "Gentona Book";
    src: local("Gentona Book"), url(./assets/fonts/Gentona-Book.woff) format("woff");
}

@font-face {
    font-family: "Gentona SemiBold";
    src: local("Gentona SemiBold"), url(./assets/fonts/Gentona-SemiBold.woff) format("woff");
}

@font-face {
    font-family: "Lyf Back Office Icons";
    src: local("Lyf Back Office Icons"), url(./assets/fonts/Lyf-Back-office-Icons.woff) format("woff");
}
