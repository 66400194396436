html {
    --desktop-background-color: #f6f9fb;
    --background-color: #ffffff;
    --text-color: #242b33;
    --text-color-secondary: #a2a7be;
    --text-color-error: #fe6b70;
    --button-background-color: #fe6b70;
    --button-border-color: #fe6b70;
    --button-hover: #f26065;
    --button-text-color: #ffffff;
    --button-disabled: #d5dae8;
    --input-border-color: #eaecf3;
    --input-text-color: #242b33;
    --input-background-color: #eaecf3;
    --card-border-color: #d5dae8;
    --card-background-color: #ffffff;
    --card-hover: #f7f7f7;
    --logo-border-color: #d5dae8;
    --link-color: #00c0b5;
    --spinner-color: #fe6b70;
    --button-spinner-color: #ffffff;
    --cancel-color: #fe6b70;
    --card-radius: 12px;
    --button-radius: 12px;
    --logo-radius: 24px;
    --font-default: "Gentona Book";
    --font-medium: "Gentona Medium";
    --font-heavy: "Gentona Semibold";
}
